import { Navigate, useLocation } from 'react-router-dom';
import ErrorPage from '../pages/ErrorPage';

const PrivateRoute = ({ user, allowedRoles, children }) => {
    const location = useLocation();
    if (!user) {
        return <Navigate to="/login" />;
    }

    if (user.role === 'INTESA_MERCHANT') {
        const merchantPath = `/merchants/${user.service_id || 1}`;
        const allowedMerchantRoutes = [
            merchantPath,
            `${merchantPath}/offers/${location.pathname.split('/').pop()}`
        ];

        if (!allowedMerchantRoutes.some(route => location.pathname.startsWith(route))) {
            return <ErrorPage />;
        }
    }

    if (user.role === 'INTESA_LOCATION') {
        const expectedPath = `/location`;
        if (location.pathname !== expectedPath) {
            return <Navigate to={expectedPath} replace />;
        }
    }

    if (!allowedRoles.includes(user.role)) {
        return <Navigate to={`/merchants/${user.service_id || ''}`} />;
    }

    return children;
};

export default PrivateRoute;
