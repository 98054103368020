import React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import OffersLocationsTabs from '../components/OffersLocationsTabs';


import '../styles/MerchantDetails.css'


const MerchantDetails = () => {
    const { id } = useParams();
    const [merchant, setMerchant] = useState(null);

    const stripHtmlTags = (html) => {
        return html ? html.replace(/<[^>]*>/g, '') : '';
    };


    useEffect(() => {
        const fetchMerchantDetails = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/merchants/${id}`, {
                    method: 'GET',
                    credentials: 'include'
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                setMerchant(data);
            } catch (error) {
                console.error('Failed to fetch merchant details:', error);
            }
        };


        fetchMerchantDetails();
    }, [id]);

    if (!merchant) return <div>Loading...</div>;



    return (
        <>
            <div className="merchant-details">
                <div className="merchant-info">
                    <h2>Merchant Details</h2>
                    <div className="info-item">
                        <label>ID: </label>
                        <span>{merchant.service_id}</span>
                    </div>
                    <div className="info-item">
                        <label>Category: </label>
                        <span>{merchant.category_title}</span>
                    </div>
                    <div className="info-item">
                        <label>Company Name: </label>
                        <span>{merchant.service_title}</span>
                    </div>
                    <div className="info-item">
                        <label>Description: </label>
                        <span>{stripHtmlTags(merchant.short_description)}</span>
                    </div>
                </div>
                <div className="merchant-logo">
                    <img src={merchant.small_image_url} alt={merchant.companyname} />
                </div>
            </div>
            <OffersLocationsTabs merchantID={id} />
        </>
    );
};

export default MerchantDetails;
