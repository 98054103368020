import { useEffect } from 'react';

export const useAutoLogout = (user, logout) => {
    useEffect(() => {
        if (user) {
            const now = new Date();
            const storedUser = JSON.parse(localStorage.getItem('user'));
            const expiryTime = storedUser?.expiry || 0;
            const timeRemaining = expiryTime - now.getTime();

            if (timeRemaining > 0) {
                const timeout = setTimeout(() => {
                    logout();
                }, timeRemaining);

                return () => clearTimeout(timeout);
            } else {
                logout();
            }
        }
    }, [user, logout]);
};
