import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import { useLocation } from 'react-router-dom';
import { useLogout } from '../hooks/useLogout';
import { useAuthContext } from '../hooks/useAuthContext';
import '../styles/NavigationTabs.css';

function NavigationTabs() {
    const { user } = useAuthContext();
    const { logout } = useLogout();
    const location = useLocation();

    const navStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        zIndex: 1000,
    };

    if (!user) {
        return null;
    }

    const handleClick = () => {
        logout();
    };

    return (
        <Navbar bg="light" style={navStyle} className="p-3">
            <Nav variant="underline" defaultActiveKey={location.pathname}>
                {(user.role === 'INTESA_ADMIN' || user.role === 'INTESA_SUPER_ADMIN') && (
                    <>
                        <Nav.Item>
                            <Nav.Link href="/merchants" className="custom-link">
                                Merchants
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="/alloffers" className="custom-link">
                                Offers
                            </Nav.Link>
                        </Nav.Item>
                    </>
                )}
            </Nav>
            <div className="ms-auto">
                <span className="me-3 username-display">
                    {user.username || ''}
                </span>
                <Button
                    onClick={handleClick}
                    variant="outline-dark"
                    className="custom-logout-btn"
                >
                    Logout
                </Button>
            </div>
        </Navbar>
    );
}

export default NavigationTabs;
