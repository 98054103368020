import React, { useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

const EditLocationModal = ({ show, handleClose, locationData, onSubmit }) => {
    const { register, handleSubmit, reset } = useForm({
    });

    useEffect(() => {
        if (locationData) {
            reset({
                name: locationData.name || '',
                address: locationData.address || '',
                username: locationData.username || '',
                real_location: locationData.real_location || false,
            });
        }
    }, [locationData, reset]);

    const onFormSubmit = (data) => {
        onSubmit({ ...data, id: locationData.id });
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Location</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit(onFormSubmit)}>
                    <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            {...register('name', { required: 'Name is requred.' })}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                            type="text"
                            {...register('address', { required: 'Address is required.' })}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>User Name</Form.Label>
                        <Form.Control
                            type="text"
                            {...register('username', { required: 'User Name is required.' })}
                        />
                    </Form.Group>
                    <Form.Group controlId="real_location" className="mt-3">
                        <Form.Check
                            type="checkbox"
                            label="Real Location"
                            {...register('real_location')}
                        />
                    </Form.Group>
                    <div className="d-flex justify-content-end mt-4">
                        <Button variant="secondary" onClick={handleClose} style={{ marginRight: '10px' }}>
                            Cancel
                        </Button>
                        <Button variant="primary" type="submit">
                            Save Changes
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default EditLocationModal;
