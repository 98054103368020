import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';

function NewLocationModal({ show, handleClose, onSubmit }) {
    const { register, handleSubmit, formState: { errors }, reset } = useForm();

    const handleFormSubmit = (data) => {
        const newLocation = {
            name: data.locationName,
            address: data.address,
            username: data.username,
            real_location: data.real_location || false,
        }
        onSubmit(newLocation);
        reset();
        handleClose();
    };

    return (
        <Modal
            show={show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={handleClose}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add New Location
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <div className="mb-3">
                        <label htmlFor="locationName" className="form-label">Location Name</label>
                        <input
                            id="locationName"
                            className="form-control"
                            {...register('locationName', { required: true })}
                        />
                        {errors.locationName && <span className="text-danger">Locaton name is required.</span>}
                    </div>

                    <div className="mb-3">
                        <label htmlFor="address" className="form-label">Address</label>
                        <input
                            id="address"
                            className="form-control"
                            {...register('address', { required: true })}
                        />
                        {errors.address && <span className="text-danger">Adress is required</span>}
                    </div>

                    <div className="mb-3">
                        <label htmlFor="username" className="form-label">User Name</label>
                        <input
                            id="username"
                            className="form-control"
                            {...register('username', { required: true })}
                        />
                        {errors.address && <span className="text-danger">User name is required</span>}
                    </div>

                    <div className="mb-3 form-check">
                        <input
                            type="checkbox"
                            id="real_location"
                            className="form-check-input"
                            {...register('real_location')}
                        />
                        <label htmlFor="real_location" className="form-check-label">Real Location</label>
                    </div>

                    <div className="d-flex justify-content-end mt-4">
                        <Button variant="secondary" onClick={handleClose} style={{ marginRight: '10px' }}>
                            Cancel
                        </Button>
                        <Button variant="primary" type="submit">
                            Save
                        </Button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default NewLocationModal;
