import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Row, Col, Alert } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

const EditOfferModal = ({ show, handleClose, onSubmit, offerData }) => {
    const { register, handleSubmit, getValues, reset, formState: { errors } } = useForm();
    const [charCount, setCharCount] = useState({
        offerTitle: 0,
        shortDescription: 0,
        longDescription: 0,
        howTo: 0,
        otherInfo: 0,
        physicalStoreDesc: 0,
    });
    const [warning, setWarning] = useState('');
    const [isEditableOfferCode, setIsEditableOfferCode] = useState(false);
    const [isEditableProductCode, setIsEditableProductCode] = useState(false);

    const handleInputChange = (field, value) => {
        setCharCount((prev) => ({
            ...prev,
            [field]: value.length,
        }));
    };

    const toggleOfferCodeEdit = () => {
        setIsEditableOfferCode((prev) => !prev);
    };

    const toggleProductCodeEdit = () => {
        setIsEditableProductCode((prev) => !prev);
    };

    const checkCodes = (offerCode, productCode) => {
        if (offerCode !== productCode) {
            setWarning('Warning: Offer Code and Product Code must match!');
        } else {
            setWarning('');
        }
    };

    const formatDate = (date) => {
        if (!date) return '';
        const parsedDate = new Date(date);
        return new Date(parsedDate.getTime() - parsedDate.getTimezoneOffset() * 60000)
            .toISOString()
            .split('T')[0];
    };

    useEffect(() => {
        if (offerData) {
            reset({
                offerTitle: offerData.name || '',
                offerType: offerData.intesa_offer_name || '',
                offerCode: offerData.offer_code || '',
                productCode: offerData.product_code || '',
                shortDescription: offerData.short_description || '',
                longDescription: offerData.description || '',
                validFrom: formatDate(offerData.start_time) || '',
                validTo: formatDate(offerData.end_time) || '',
                couponImage: offerData.image_url || '',
                productBanner: offerData.large_image_url || '',
                physicalStore: offerData.physical_store || false,
                howTo: offerData.how_to || '',
                otherInfo: offerData.other_info || '',
                published: offerData.published || false,
                verticalImageURL: offerData.vertical_image_url || '',
                infoOffer: offerData.info_offer,
                physicalStoreDesc: offerData.physical_store_desc,

            });

            setCharCount({
                offerTitle: offerData.name?.length || 0,
                shortDescription: offerData.short_description?.length || 0,
                longDescription: offerData.description?.length || 0,
                howTo: offerData.how_to?.length || 0,
                otherInfo: offerData.other_info?.length || 0,
                physicalStoreDesc: offerData.physical_store_desc?.length || 0,
            });
            checkCodes(offerData.offer_code, offerData.product_code);
        }

    }, [offerData, reset]);

    useEffect(() => {
        if (!show) {
            setWarning('');
            setIsEditableOfferCode(false);
            setIsEditableProductCode(false);
        }
    }, [show]);

    const handleFormSubmit = () => {
        const updatedOffer = getValues();
        const offerWithId = { ...updatedOffer, offer_id: offerData.offer_id };
        onSubmit(offerWithId);
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleClose} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>Edit Offer</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit(handleFormSubmit)}>
                    <Row>
                        <Col md={6}>
                            <Form.Group controlId="offerTitle">
                                <Form.Label>Offer Title</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter offer title"
                                    {...register('offerTitle', { required: 'Offer title is required' })}
                                    onChange={(e) => handleInputChange('offerTitle', e.target.value)}
                                />
                                <div className="text-muted">
                                    {charCount.offerTitle}/35 characters
                                </div>
                                {errors.offerTitle && <Form.Text className="text-danger">{errors.offerTitle.message}</Form.Text>}
                            </Form.Group>

                            <Form.Group controlId="offerType" className="mt-3">
                                <Form.Label>Offer Type</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter offer type"
                                    {...register('offerType', { required: 'Offer type is required' })}
                                />
                                {errors.offerType && <Form.Text className="text-danger">{errors.offerType.message}</Form.Text>}
                            </Form.Group>

                            <Form.Group controlId="offerCode" className="mt-3">
                                <Form.Label>Offer Code</Form.Label>
                                <div className="d-flex">
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter offer code"
                                        {...register('offerCode', { required: 'Offer code is required' })}
                                        readOnly={!isEditableOfferCode}
                                        onChange={(e) => {
                                            handleInputChange('offerCode', e.target.value);
                                            checkCodes(e.target.value, getValues('productCode'));
                                        }}
                                    />
                                    <Button
                                        variant={isEditableOfferCode ? "danger" : "secondary"}
                                        onClick={toggleOfferCodeEdit}
                                        className="ms-2"
                                    >
                                        {isEditableOfferCode ? 'Editable' : 'Locked'}
                                    </Button>
                                </div>
                                {errors.offerCode && <Form.Text className="text-danger">{errors.offerCode.message}</Form.Text>}
                            </Form.Group>

                            {warning && <Alert variant="light" className="text-danger mt-2">{warning}</Alert>}

                            <Form.Group controlId="productCode" className="mt-3">
                                <Form.Label>Product Code</Form.Label>
                                <div className="d-flex">
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter product code"
                                        {...register('productCode', { required: 'Product code is required' })}
                                        readOnly={!isEditableProductCode}
                                        onChange={(e) => {
                                            handleInputChange('productCode', e.target.value);
                                            checkCodes(getValues('offerCode'), e.target.value);
                                        }}
                                    />
                                    <Button
                                        variant={isEditableProductCode ? "danger" : "secondary"}
                                        onClick={toggleProductCodeEdit}
                                        className="ms-2"
                                    >
                                        {isEditableProductCode ? 'Editable' : 'Locked'}
                                    </Button>
                                </div>
                                {errors.productCode && <Form.Text className="text-danger">{errors.productCode.message}</Form.Text>}
                            </Form.Group>

                            <Form.Group controlId="shortDescription" className="mt-3">
                                <Form.Label>Short Description</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={2}
                                    placeholder="Enter short description"
                                    {...register('shortDescription')}
                                    onChange={(e) => handleInputChange('shortDescription', e.target.value)}
                                />
                                <div className="text-muted">
                                    {charCount.shortDescription}/1000 characters
                                </div>
                            </Form.Group>

                            <Form.Group controlId="longDescription" className="mt-3">
                                <Form.Label>Long Description</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    placeholder="Enter long description"
                                    {...register('longDescription')}
                                    onChange={(e) => handleInputChange('longDescription', e.target.value)}
                                />
                                <div className="text-muted">
                                    {charCount.longDescription}/2000 characters
                                </div>
                            </Form.Group>

                            <Form.Group controlId="physicalStoreDesc" className="mt-3">
                                <Form.Label>Location - Physical Store Description</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    placeholder="Enter Loaction information"
                                    {...register('physicalStoreDesc')}
                                    onChange={(e) => handleInputChange('physicalStoreDesc', e.target.value)}
                                />
                                <div className="text-muted">
                                    {charCount.physicalStoreDesc}/2000 characters
                                </div>
                            </Form.Group>

                        </Col>

                        <Col md={6}>
                            <Form.Group controlId="validFrom">
                                <Form.Label>Valid From</Form.Label>
                                <Form.Control
                                    type="date"
                                    {...register('validFrom', { required: 'Valid from date is required' })}
                                />
                                {errors.validFrom && <Form.Text className="text-danger">{errors.validFrom.message}</Form.Text>}
                            </Form.Group>

                            <Form.Group controlId="validTo" className="mt-3">
                                <Form.Label>Valid To</Form.Label>
                                <Form.Control
                                    type="date"
                                    {...register('validTo', { required: 'Valid to date is required' })}
                                />
                                {errors.validTo && <Form.Text className="text-danger">{errors.validTo.message}</Form.Text>}
                            </Form.Group>

                            <Form.Group controlId="verticalImageURL" className="mt-3">
                                <Form.Label>Vertical Image URL - 230x330</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter vertical image URL"
                                    {...register('verticalImageURL', { required: 'Vertical image URL is required' })}
                                />
                                {errors.verticalImageURL && <Form.Text className="text-danger">{errors.verticalImageURL.message}</Form.Text>}
                            </Form.Group>

                            <Form.Group controlId="couponImage" className="mt-3">
                                <Form.Label>Coupon Image - 366x240</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter coupon image URL"
                                    {...register('couponImage', { required: 'Coupon image URL is required' })}
                                />
                                {errors.couponImage && <Form.Text className="text-danger">{errors.couponImage.message}</Form.Text>}
                            </Form.Group>

                            <Form.Group controlId="productBanner" className="mt-3">
                                <Form.Label>Product Banner URL - 860x542</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter product banner URL"
                                    {...register('productBanner', { required: 'Product banner URL is required' })}
                                />
                                {errors.productBanner && <Form.Text className="text-danger">{errors.productBanner.message}</Form.Text>}
                            </Form.Group>

                            <Form.Group controlId="howTo" className="mt-3">
                                <Form.Label>How To</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={2}
                                    placeholder="Enter how-to instructions"
                                    {...register('howTo')}
                                    onChange={(e) => handleInputChange('howTo', e.target.value)}
                                />
                                <div className="text-muted">
                                    {charCount.howTo}/2000 characters
                                </div>
                            </Form.Group>

                            <Form.Group controlId="otherInfo" className="mt-3">
                                <Form.Label>Other Info</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={2}
                                    placeholder="Enter other information"
                                    {...register('otherInfo')}
                                    onChange={(e) => handleInputChange('otherInfo', e.target.value)}
                                />
                                <div className="text-muted">
                                    {charCount.otherInfo}/2000 characters
                                </div>
                            </Form.Group>

                            <Form.Group controlId="published" className="mt-3">
                                <Form.Check
                                    type="checkbox"
                                    label="Is Published"
                                    {...register('published')}
                                />
                            </Form.Group>

                            <Form.Group controlId="physicalStore" className="mt-3">
                                <Form.Check
                                    type="checkbox"
                                    label="Physical Store"
                                    {...register('physicalStore')}
                                />
                            </Form.Group>

                            <Form.Group controlId="infoOffer" className="mt-3">
                                <Form.Check
                                    type="checkbox"
                                    label="Info Offer"
                                    {...register('infoOffer')}
                                />
                            </Form.Group>

                        </Col>
                    </Row>
                    <div className="d-flex justify-content-end mt-4">
                        <Button variant="secondary" onClick={handleClose} style={{ marginRight: '10px' }}>
                            Cancel
                        </Button>
                        <Button variant="primary" type="submit">
                            Save Changes
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default EditOfferModal;
