import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import '../styles/OfferDetails.css';

const fetchOfferDetails = async ({ queryKey }) => {
    const [, { merchantId, offerId }] = queryKey;
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/merchants/${merchantId}/offers/${offerId}`, {
        method: 'GET',
        credentials: 'include',
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to fetch offer details.');
    }

    return response.json();
};

const OfferDetails = () => {
    const { merchantId, offerId } = useParams();

    const { data: offer, error, isLoading } = useQuery({
        queryKey: ['offerDetails', { merchantId, offerId }],
        queryFn: fetchOfferDetails,
    });

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div className="error-message">{error.message}</div>;

    const columnDefs = [
        { headerName: 'Coupon Code', field: 'code', sortable: true, filter: true },
        {
            headerName: 'Date Claimed',
            field: 'userAssignedTime',
            valueFormatter: (params) => {
                if (!params.value) return '';
                return new Intl.DateTimeFormat('sr-RS', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                }).format(new Date(params.value));
            },
            filter: 'agDateColumnFilter',
            filterParams: {
                comparator: (filterDate, cellValue) => {
                    if (!cellValue) return -1;
                    const cellDate = new Date(cellValue);
                    return cellDate.getTime() - filterDate.getTime();
                },
            },
        },
        { headerName: 'Claimed By', field: 'userReference', sortable: true, filter: true },
        {
            headerName: 'Date Redeemed',
            field: 'activationTime',
            filter: 'agDateColumnFilter',
            filterParams: {
                comparator: (filterDate, cellValue) => {
                    if (!cellValue) return -1;
                    const cellDate = new Date(cellValue);
                    return cellDate.getTime() - filterDate.getTime();
                },
            },
            valueFormatter: (params) => {
                if (!params.value) return '';
                return new Intl.DateTimeFormat('sr-RS', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                }).format(new Date(params.value));
            },
        },
        { headerName: 'Location Redeemed', field: 'activationLocation', sortable: true, filter: true },
    ];

    return (
        <>
            <div className="offer-details-container">
                <div className="offer-details">
                    <h2>Offer Details</h2>
                    <div className="detail-item">
                        <label>Offer ID: </label>
                        <span>{offer.offerId}</span>
                    </div>
                    <div className="detail-item">
                        <label>Valid From: </label>
                        <span>{new Intl.DateTimeFormat('sr-RS').format(new Date(offer.validFrom))}</span>
                    </div>
                    <div className="detail-item">
                        <label>Coupons Generated: </label>
                        <span>{offer.couponsGenerated}</span>
                    </div>
                    <div className="detail-item">
                        <label>Title: </label>
                        <span>{offer.title}</span>
                    </div>
                    <div className="detail-item">
                        <label>Valid To: </label>
                        <span>{new Intl.DateTimeFormat('sr-RS').format(new Date(offer.validTo))}</span>
                    </div>
                    <div className="detail-item">
                        <label>Coupons Claimed: </label>
                        <span>{offer.couponsClaimed}</span>
                    </div>
                    <div className="detail-item">
                        <label>Merchant Name: </label>
                        <span>{offer.merchantName}</span>
                    </div>
                    <div className="detail-item">
                        <label>Days Left: </label>
                        <span>{offer.daysLeft}</span>
                    </div>
                    <div className="detail-item">
                        <label>Percentage Claimed: </label>
                        <span>{offer.percentageClaimed}%</span>
                    </div>
                </div>

                <div className="offer-image">
                    <img src={offer.image} alt={offer.title} />
                </div>
            </div>
            <div className='offer-details-table'>
                <div className="ag-theme-alpine" style={{ height: 400 }}>
                    <AgGridReact
                        rowData={offer.tableData}
                        columnDefs={columnDefs}
                        pagination={true}
                        paginationPageSize={10}
                        enableCellTextSelection={true}
                    />
                </div>
            </div>
        </>
    );
};

export default OfferDetails;
