import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col, Alert } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

const NewOfferModal = ({ show, handleClose, onSubmit }) => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [charCount, setCharCount] = useState({
        offerTitle: 0,
        shortDescription: 0,
        longDescription: 0,
        howTo: 0,
        otherInfo: 0,
        physicalStoreDesc: 0,
    });
    const [warning, setWarning] = useState('');
    const [offerCode, setOfferCode] = useState('');
    const [productCode, setProductCode] = useState('');

    const handleOfferCodeChange = (value) => {
        setOfferCode(value);
        checkCodes(value, productCode);
    };

    const handleProductCodeChange = (value) => {
        setProductCode(value);
        checkCodes(offerCode, value);
    };

    const checkCodes = (offerCodeValue, productCodeValue) => {
        if (offerCodeValue !== productCodeValue) {
            setWarning('Warning: Offer Code and Product Code must be identical!');
        } else {
            setWarning('');
        }
    };

    const handleInputChange = (field, value) => {
        setCharCount((prev) => ({
            ...prev,
            [field]: value.length,
        }));
    };

    const handleFormSubmit = (data) => {
        const newOffer = {
            name: data.offerTitle,
            intesa_offer_name: data.offerType,
            offer_code: data.offerCode,
            product_code: data.productCode,
            short_description: data.shortDescription,
            description: data.longDescription,
            start_time: data.validFrom,
            end_time: data.validTo,
            image_url: data.couponImage,
            large_image_url: data.productBanner,
            how_to: data.howTo,
            other_info: data.otherInfo,
            physical_store: data.physicalStore || false,
            published: data.published || false,
            vertical_image_url: data.verticalImage,
            info_offer: data.infoOffer || false,
            physical_store_desc: data.physicalStoreDesc,
        };
        onSubmit(newOffer);
        reset();
        handleClose();
    };

    useEffect(() => {
        if (!show) {
            reset();
            setOfferCode('');
            setProductCode('');
            setWarning('');
            setCharCount({
                offerTitle: 0,
                shortDescription: 0,
                longDescription: 0,
                howTo: 0,
                otherInfo: 0,
                physicalStoreDesc: 0,
            });
        }
    }, [show, reset]);

    return (
        <Modal show={show} onHide={handleClose} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>New Offer</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit(handleFormSubmit)}>
                    <Row>
                        <Col md={6}>
                            <Form.Group controlId="offerTitle">
                                <Form.Label>Offer Title</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter offer title"
                                    {...register('offerTitle', { required: 'Offer title is required' })}
                                    onChange={(e) => handleInputChange('offerTitle', e.target.value)}
                                />
                                <div className="text-muted">
                                    {charCount.offerTitle}/35 characters
                                </div>
                                {errors.offerTitle && <span className="text-danger">{errors.offerTitle.message}</span>}
                            </Form.Group>

                            <Form.Group controlId="offerType" className="mt-3">
                                <Form.Label>Offer Type</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter offer type"
                                    {...register('offerType', { required: 'Offer type is required' })}
                                />
                                {errors.offerType && <span className="text-danger">{errors.offerType.message}</span>}
                            </Form.Group>

                            <Form.Group controlId="offerCode" className="mt-3">
                                <Form.Label>Offer Code</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter offer code"
                                    {...register('offerCode', { required: 'Offer code is required' })}
                                    value={offerCode}
                                    onChange={(e) => handleOfferCodeChange(e.target.value)}
                                />
                                {errors.offerCode && <span className="text-danger">{errors.offerCode.message}</span>}
                            </Form.Group>

                            {warning && <Alert variant="light" className="text-danger mt-2">{warning}</Alert>}

                            <Form.Group controlId="productCode" className="mt-3">
                                <Form.Label>Product Code</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter product code"
                                    {...register('productCode', { required: 'Product code is required' })}
                                    value={productCode}
                                    onChange={(e) => handleProductCodeChange(e.target.value)}
                                />
                                {errors.offerCode && <span className="text-danger">{errors.productCode.message}</span>}
                            </Form.Group>

                            <Form.Group controlId="shortDescription" className="mt-3">
                                <Form.Label>Short Description</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={2}
                                    placeholder="Enter short description"
                                    {...register('shortDescription')}
                                    onChange={(e) => handleInputChange('shortDescription', e.target.value)}
                                />
                                <div className="text-muted">
                                    {charCount.shortDescription}/1000 characters
                                </div>
                            </Form.Group>

                            <Form.Group controlId="longDescription" className="mt-3">
                                <Form.Label>Long Description</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    placeholder="Enter long description"
                                    {...register('longDescription')}
                                    onChange={(e) => handleInputChange('longDescription', e.target.value)}
                                />
                                <div className="text-muted">
                                    {charCount.longDescription}/2000 characters
                                </div>
                            </Form.Group>

                            <Form.Group controlId="physicalStoreDesc" className="mt-3">
                                <Form.Label>Location - Physical Store Description</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    placeholder="Enter Loaction information"
                                    {...register('physicalStoreDesc')}
                                    onChange={(e) => handleInputChange('physicalStoreDesc', e.target.value)}
                                />
                                <div className="text-muted">
                                    {charCount.physicalStoreDesc}/2000 characters
                                </div>
                            </Form.Group>

                        </Col>

                        <Col md={6}>
                            <Form.Group controlId="validFrom">
                                <Form.Label>Valid From</Form.Label>
                                <Form.Control
                                    type="date"
                                    {...register('validFrom', { required: 'Valid from date is required' })}
                                />
                                {errors.validFrom && <span className="text-danger">{errors.validFrom.message}</span>}
                            </Form.Group>

                            <Form.Group controlId="validTo" className="mt-3">
                                <Form.Label>Valid To</Form.Label>
                                <Form.Control
                                    type="date"
                                    {...register('validTo', { required: 'Valid to date is required' })}
                                />
                                {errors.validTo && <span className="text-danger">{errors.validTo.message}</span>}
                            </Form.Group>

                            <Form.Group controlId="verticalImage" className="mt-3">
                                <Form.Label>Vertical Image URL - 230x330</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter vertical image URL"
                                    {...register('verticalImage', { required: 'Vertical image URL is required' })}
                                />
                                {errors.verticalImage && <span className="text-danger">{errors.verticalImage.message}</span>}
                            </Form.Group>

                            <Form.Group controlId="couponImage" className="mt-3">
                                <Form.Label>Coupon Image URL - 366x240</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter coupon image URL"
                                    {...register('couponImage', { required: 'Coupon image URL is required' })}
                                />
                                {errors.couponImage && <span className="text-danger">{errors.couponImage.message}</span>}
                            </Form.Group>

                            <Form.Group controlId="productBanner" className="mt-3">
                                <Form.Label>Product Banner URL - 860x542</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter product banner URL"
                                    {...register('productBanner', { required: 'Product banner URL is required' })}
                                />
                                {errors.productBanner && <span className="text-danger">{errors.productBanner.message}</span>}
                            </Form.Group>

                            <Form.Group controlId="howTo" className="mt-3">
                                <Form.Label>How To</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={2}
                                    placeholder="Enter how-to instructions"
                                    {...register('howTo')}
                                    onChange={(e) => handleInputChange('howTo', e.target.value)}
                                />
                                <div className="text-muted">
                                    {charCount.howTo}/2000 characters
                                </div>
                            </Form.Group>

                            <Form.Group controlId="otherInfo" className="mt-3">
                                <Form.Label>Other Info</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={2}
                                    placeholder="Enter other information"
                                    {...register('otherInfo')}
                                    onChange={(e) => handleInputChange('otherInfo', e.target.value)}
                                />
                                <div className="text-muted">
                                    {charCount.otherInfo}/2000 characters
                                </div>
                            </Form.Group>

                            <Form.Group controlId="published" className="mt-3">
                                <Form.Check
                                    type="checkbox"
                                    label="Is Published"
                                    {...register('published')}
                                />
                            </Form.Group>

                            <Form.Group controlId="physicalStore" className="mt-3">
                                <Form.Check
                                    type="checkbox"
                                    label="Physical Store"
                                    {...register('physicalStore')}
                                />
                            </Form.Group>

                            <Form.Group controlId="infoOffer" className="mt-3">
                                <Form.Check
                                    type="checkbox"
                                    label="Info Offer"
                                    {...register('infoOffer')}
                                />
                            </Form.Group>

                        </Col>
                    </Row>
                    <div className="d-flex justify-content-end mt-4">
                        <Button variant="secondary" onClick={handleClose} style={{ marginRight: '10px' }}>
                            Cancel
                        </Button>
                        <Button variant="primary" type="submit">
                            Save
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default NewOfferModal;
