import React, { useEffect, useState, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useNavigate } from 'react-router-dom';

import '../styles/ListOfCardholders.css';

const ListOfCardholders = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [cardholders, setCardholders] = useState([]);

    const defaultColDef = useMemo(() => ({
        filter: true,

    }), []);


    useEffect(() => {
        const fetchCardholders = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/cardholders`, {
                    method: 'GET',
                    credentials: 'include'
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setCardholders(data);

            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchCardholders();
    }, []);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    const columns = [
        { headerName: 'Cardholder ID', field: 'id' },
        { headerName: 'Email', field: 'email' },
        { headerName: 'Segment', field: 'segment' },
        { headerName: 'Subsegment', field: 'subsegment' },
        { headerName: 'Active Vouchers', field: 'activevouchers' },
        { headerName: 'Claimed to date', field: 'claimedtodate' },
        { headerName: 'Redeemed to date', field: 'redeemedtodate' },
        {
            headerName: 'Actions',
            cellRenderer: (params) => (
                <button
                    onClick={() => navigate(`/cardholders/${params.data.id}`)}
                    style={{ marginLeft: '10px' }}
                >
                    View
                </button>
            )
        },
    ];



    return (
        <div className="cardholders-page">
            <h2>Cardholders</h2>
            <div className="ag-theme-alpine" style={{ height: 400 }}>
                <AgGridReact
                    rowData={cardholders}
                    columnDefs={columns}
                    defaultColDef={defaultColDef}
                    pagination={true}
                    paginationPageSize={10}
                />
            </div>
        </div>
    );
};

export default ListOfCardholders;
